import React from 'react';
import styled from 'styled-components';

import { mediaQueries } from 'shared/styles';
import { StyledLogo } from './login.styles';

export const DutchieV2Slide = (): JSX.Element => (
  <Wrapper>
    <StyledLogo fill='#fff' />

    <Content>
      <Heading>
        <b>Dutchie 2.0:</b> The cannabis operating system, reimagined.
      </Heading>

      <Button
        href='https://business.dutchie.com/?utm_medium=in-app&utm_source=dpos_product&utm_campaign=base_2.0_launch_DPOSlogin&utm_content=base_base_link'
        target='_blank'
      >
        See what’s new
      </Button>
    </Content>

    <Image>
      <img
        src='https://images.dutchie.com/dutchie-2.0/slide-hero-image.png'
        alt='Screenshots showing new Dutchie app features'
      />
    </Image>
  </Wrapper>
);

const Wrapper = styled.div`
  align-items: center;
  background: #607bb6 url(https://images.dutchie.com/dutchie-2.0/slide-background.png);
  background-size: cover;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  padding: 100px 40px;
  position: relative;
  text-align: center;
  width: 45%;

  @media ${mediaQueries.largeDesktop} {
    width: 100%;
  }
`;

const Content = styled.div`
  max-width: 610px;
`;

const Heading = styled.h2`
  font-size: 46px;
  font-weight: 400;
  line-height: ${55 / 46};
  margin-bottom: 32px;

  @media ${mediaQueries.largeDesktop} {
    font-size: 32px;
  }

  b {
    display: block;
    font-weight: 700;
  }
`;

const Button = styled.a`
  background: #0075e0;
  color: #fff;
  border-radius: 5px;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  line-height: ${21 / 16};
  padding: 10px 16px;

  &:hover {
    color: #fff;
  }
`;

const Image = styled.div`
  max-width: 728px;

  img {
    display: block;
  }
`;
