import { useEffect } from 'react';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import type { LDContext } from 'launchdarkly-js-client-sdk';
import { useObserver } from 'mobx-react-lite';

import { getPersistedValue } from 'shared/utils/persisted-values';
import { defaultUser } from 'shared/constants/users';
import { useStores } from 'src/hooks/use-stores';
import { useUser } from 'src/hooks/use-user';
import { useUI } from 'src/hooks/use-ui';

export function useFeatureFlagsController(): void {
  const flags = useFlags();
  const stores = useStores();
  const ldClient = useLDClient();
  const User = useUser();
  const UI = useUI();

  const dispensaryId = useObserver(() => UI.dispensary.id);
  const dispensaryChain = useObserver(() => UI.dispensary.chain);
  const dispensaryCName = useObserver(() => UI.dispensary.cName);
  const dispensaryLoading = useObserver(() => UI.dispensaryLoading);
  const enterpriseId = useObserver(() => UI.dispensary.retailer?.enterpriseId);
  const userType = useObserver(() => User.profile.type);
  const isCanaryUser = getPersistedValue(`launch-darkly-canary-user`, false);

  useEffect(() => {
    if (dispensaryLoading) {
      return;
    }
    if (!ldClient) {
      return;
    }
    // userType should always be defined, but || makes typescript and failure cases happier
    const context: LDContext = {
      kind: 'multi',
      user: {
        ...defaultUser,
        ...(userType && { type: userType }),
        canary: isCanaryUser,
      },
    };

    if (dispensaryId && dispensaryCName) {
      context['ecomm-dispensary'] = {
        key: dispensaryId,
        // leaving these here in case we want them later / to show it's possible
        // we can add arbitrary key/value pairs and target based on those "attributes"
        // cName: dispensaryCName,
        // chain: dispensaryChain || null,
      };
    }
    if (enterpriseId) {
      context['ecomm-enterprise'] = {
        key: enterpriseId,
      };
    }
    void ldClient.identify(context);
  }, [
    ldClient,
    dispensaryLoading,
    userType,
    dispensaryId,
    dispensaryChain,
    dispensaryCName,
    enterpriseId,
    isCanaryUser,
  ]);

  useEffect(() => {
    stores.FeatureFlags.setFlags(flags);
  }, [stores.FeatureFlags, flags]);
}
