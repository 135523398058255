import React from 'react';
import { Flex } from 'rebass';
import styled from 'styled-components';

import { ModalContainer, ModalButton, ModalSecondaryHeader, ModalClose, ModalCopy } from 'shared/modals';

type WarningModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onClickNo?: () => void;
  text: string;
  body?: string;
  modalWidth?: string;
  yesText?: string;
  noText?: string;
  buttonGap?: string;
  buttonContainerWidth?: string;
  buttonWidth?: string;
  loading?: boolean;
};

export default function WarningModal({
  isOpen,
  onClose,
  onConfirm,
  onClickNo,
  text,
  body,
  modalWidth = '462px',
  yesText = 'Yes',
  noText = 'No',
  buttonGap = '0',
  buttonContainerWidth = '119px',
  buttonWidth = 'auto',
  loading = false,
}: WarningModalProps): JSX.Element {
  return (
    <ModalContainer
      data-test='warning-modal'
      isOpen={isOpen}
      onRequestClose={onClose}
      padding='32px 48px'
      width={modalWidth}
    >
      <ModalSecondaryHeader fontSize='18px'>{text}</ModalSecondaryHeader>

      {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
      {body && <ModalCopy dangerouslySetInnerHTML={{ __html: body }} />}

      <StyledFlex mt={body ? '10px' : '0px'} $gap={buttonGap}>
        <ModalButton
          data-testid='confirm-modal-button'
          loading={loading}
          buttonContainerWidth={buttonContainerWidth}
          width={buttonWidth}
          mt='0px'
          onClick={onConfirm}
        >
          {yesText}
        </ModalButton>
        <ModalButton
          data-testid='cancel-modal-button'
          inverted
          buttonContainerWidth={buttonContainerWidth}
          width={buttonWidth}
          mt='0px'
          onClick={onClickNo ?? onClose}
        >
          {noText}
        </ModalButton>
      </StyledFlex>

      <ModalClose onClick={onClose} />
    </ModalContainer>
  );
}

const StyledFlex = styled(Flex)<{ $gap: string }>`
  gap: ${({ $gap }) => $gap};
`;
